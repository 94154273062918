import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This lesson introduces you to the basics of the Git version control system.`}</p>
    <h2 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h2>
    <p>{`After completing this lesson, you should be comfortable...`}</p>
    <ul>
      <li parentName="ul">{`using `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git init`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git add`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git commit`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git branch`}</code>{`, and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git merge`}</code></li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <p>{`This tutorial assumes that ...`}</p>
    <ul>
      <li parentName="ul">{`You have a working `}<a parentName="li" {...{
          "href": "/tutorials/virtualbox-install-ubuntu"
        }}>{`Ubuntu 20.04 LTS installation`}</a></li>
      <li parentName="ul">{`You are `}<a parentName="li" {...{
          "href": "/tutorials/linux-command-line-basics"
        }}>{`comfortable with the basics of the Linux command line`}</a></li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <h2 {...{
      "id": "do-i-really-need-version-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#do-i-really-need-version-control",
        "aria-label": "do i really need version control permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`Do I really need version control?`}</em></h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Scenario 1`}</strong>{`: You make a change to your code late into the night and saved before stumbling into bed.  When you wake up in the morning and test things you realize something broke (but what?).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Scenario 2`}</strong>{`: You're working on a project with teammates and you keep emailing scripts back and forth (is `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`crawler-final-final-v3-final.py`}</code>{` really the latest version or was it `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`crawler-ulimate-final-marvin.py`}</code>{`?  What change did Marvin make again?)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Scenario 3`}</strong>{`: You use multiple development machines on different networks.  A shared drive works when you have a stable internet connection, but you're thinking to unplug from email, escape to the beach, and finish a project without distractions.  Or maybe you have a 10+ hour international flight ahead of you and the in-cabin wifi isn't working.`}</p>
      </li>
    </ul>
    <p>{`In situations like these, you may have gotten by using something Google Drive or DropBox and its rewind feature.  Those are forms of version control systems, but they're not designed to manage `}<strong parentName="p">{`code`}</strong>{`.  `}</p>
    <p>{`Still not sold?  Keep in mind that industry jobs related to software almost always involve contributing to a shared code base which will necessitate the use of some kind of version control system.`}</p>
    <h2 {...{
      "id": "whats-git",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-git",
        "aria-label": "whats git permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`What's Git?`}</em></h2>
    <p>{`At a high level, Git is a tool to record changes to some directory (a `}<strong parentName="p">{`repository`}</strong>{`) over time and keep those changes in sync with remote "copies".`}</p>
    <p>{`Git is a popular `}<em parentName="p">{`distributed`}</em>{` version control system (DVCS) that can used for both solo and team projects.  When compared with `}<em parentName="p">{`centralized`}</em>{` version control systems (CVS), Git has a number of advantages:`}</p>
    <ul>
      <li parentName="ul">{`ability to selectively keep certain changes private (local branches, multiple remotes, etc.)`}</li>
      <li parentName="ul">{`work easily offline (connect only when exchanging information)`}</li>
      <li parentName="ul">{`no single point of failure `}
        <ul parentName="li">
          <li parentName="ul">{`multiple backups that can be both remote and local`}</li>
        </ul>
      </li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Distributed_version_control",
        "target": "_self",
        "rel": "nofollow"
      }}>{`For a detailed summary of differences between DVCS and CVS, see this link`}</a>{`.`}</p>
    <p>{`Though `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Git",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Git is designed to manage `}<strong parentName="a">{`code`}</strong>{` (`}<em parentName="a">{`large`}</em>{` codebases)`}</a>{`, some use it to manage reports and even books! `}</p>
    <p>{`With a version control system (VCS) such as Git, you can record groups of edits and "time travel" (Great Scott!) in your project's history.`}</p>
    <h2 {...{
      "id": "why-git",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-git",
        "aria-label": "why git permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`Why Git?`}</em></h2>
    <ul>
      <li parentName="ul">{`Redundancy via multiple backups (remote and local)`}
        <ul parentName="li">
          <li parentName="ul">{`Reduce risk of data loss`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Know who made what change when and why`}
        {/* - `git blame <filename`
         - `git show <commit-hash>` */}
      </li>
      <li parentName="ul">{`Currently Git is the most popular VCS`}</li>
    </ul>
    <h1 {...{
      "id": "git",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#git",
        "aria-label": "git permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Git`}</h1>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <p>{`Git can be installed on most operating systems.  To install on Ubuntu, enter the following command in the terminal:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`-y`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span></code></pre></div>
    <h3 {...{
      "id": "testing-your-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#testing-your-installation",
        "aria-label": "testing your installation permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing your installation`}</h3>
    <p>{`To test your installation on Ubuntu, enter the following command in the terminal:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`--version`}</span></code></pre></div>
    <p>{`You should see the installed version returned.`}</p>
    <h3 {...{
      "id": "configuring-git",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configuring-git",
        "aria-label": "configuring git permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring git`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# set your name`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` config `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`--global`}</span>{` user.name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Your Name"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# set your email`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` config `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`--global`}</span>{` user.email `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"your@email.com"`}</span></code></pre></div>
    <p><a parentName="p" {...{
        "href": "/tutorials/ubuntu-install-vscode"
      }}>{`If you've installed VS Code`}</a>{` and wish to use it as your preferred editor for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`git`}</code>{`, run the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` config `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`--global`}</span>{` core.editor `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"code -w"`}</span></code></pre></div>
    <p><strong parentName="p">{`NOTE: Don't forget to include `}<code parentName="strong" {...{
          "className": "language-text"
        }}>{`-w`}</code></strong></p>
    <p>{`Finally, check your settings:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` config `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`--list`}</span></code></pre></div>
    <h1 {...{
      "id": "key-concepts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#key-concepts",
        "aria-label": "key concepts permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Key concepts`}</h1>
    <p>{`Let's take a look at some of the key concepts involved in Git...`}</p>
    <h2 {...{
      "id": "repositories",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#repositories",
        "aria-label": "repositories permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Repositories`}</h2>
    <p>{`A Git repository is a directory of files (project) with information about version history (i.e., who edited what when).  The history is stored in a hidden `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.git`}</code>{` directory and composed of `}<strong parentName="p">{`commits`}</strong>{`.`}</p>
    <h2 {...{
      "id": "branches",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#branches",
        "aria-label": "branches permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Branches`}</h2>
    <p>{`You can think of Git as some kind of cosmic tree that stretches forward and backward into time.  The trunk of that tree represents the "main" timeline.  In Git, this timeline is considered to be a branch and it is usually called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`master`}</code>{`. The repository may have other branches.  Later in this tutorial, we'll look at the popular `}<a parentName="p" {...{
        "href": "/tutorials/git-basics#feature-branch"
      }}>{`feature-branch workflow`}</a>{`.`}</p>
    <h2 {...{
      "id": "file-states",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#file-states",
        "aria-label": "file states permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`File states`}</h2>
    <p>{`Each file in a Git repository will have one of the following states:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Ignored`}</strong>{`: Files that are intentionally not tracked by Git.  To ignore a file or directory, list it in a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`.gitignore`}</code>{` file. `}</li>
      <li parentName="ul"><strong parentName="li">{`Untracked`}</strong>{`: Files that are not tracked by Git.  `}</li>
      <li parentName="ul"><strong parentName="li">{`Tracked`}</strong>{`: Files that are a part of the repository's official history.  Tracking is performed via `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git add <filename>`}</code>{`.  `}</li>
      <li parentName="ul"><strong parentName="li">{`Staged`}</strong>{`: Files with changes that are ready to be committed.  Staging is performed using `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git add <filename>`}</code>{`.  `}</li>
      <li parentName="ul"><strong parentName="li">{`Modified/Dirty`}</strong>{`: A tracked file that has been altered since the last commit, but not yet staged.  `}</li>
      <li parentName="ul"><strong parentName="li">{`Committed`}</strong>{`: Changes that have been staged and explained with a commit message are committed.  Committing is performed via `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git commit -m "<informative commit message here>"`}</code>{`. `}</li>
    </ul>
    <h2 {...{
      "id": "file-states-a-closer-look",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#file-states-a-closer-look",
        "aria-label": "file states a closer look permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`File states: a closer look`}</h2>
    <p>{`Let's take a closer look at different file states.  In order to do so, though, we first need to create a repository...`}</p>
    <h3 {...{
      "id": "initializing-a-new-repository",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#initializing-a-new-repository",
        "aria-label": "initializing a new repository permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Initializing a new repository`}</h3>
    <p>{`You can create a new empty repository using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`git init`}</code>{`.`}</p>
    <p>{`First, navigate to the directory that you want to use to house your repository:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`REPO`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=~`}</span>{`/repos/git-tutorial
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`-p`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$REPO`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$REPO`}</span></code></pre></div>
    <p>{`Now initialize your repository:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` init`}</code></pre></div>
    <h3 {...{
      "id": "staging-changes-git-add",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#staging-changes-git-add",
        "aria-label": "staging changes git add permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Staging changes (`}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`git add`}</code>{`)`}</h3>
    <p>{`With Git, changes are "saved" in two steps: `}<em parentName="p">{`staging`}</em>{` and `}<em parentName="p">{`committing`}</em>{`.  `}<em parentName="p">{`Staging`}</em>{` tells Git what edits to one or more files should be considered as a group and `}<em parentName="p">{`committing`}</em>{` tells Git what those changes represent.  `}</p>
    <ol>
      <li parentName="ol">{`Within the directory containing your repository, create a file:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"# Git good at using DVCS"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` hello.md`}</code></pre></div>
    <p>{`You can of course create a file using your preferred code editor (ex. VS Code, Vim, Emacs, etc.).`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Track the file:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` hello.md`}</code></pre></div>
    <p><em parentName="p">{`TIP: If you've made a bunch of changes to a file, but want to split them between multiple commits, use `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`git add -p <filename>`}</code>{` to interactively track certain changes.`}</em></p>
    <h3 {...{
      "id": "committing-changes-git-commit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#committing-changes-git-commit",
        "aria-label": "committing changes git commit permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Committing changes (`}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`git commit`}</code>{`)`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` commit `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`-m`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Added hello.md"`}</span></code></pre></div>
    <p>{`If you message is very short and only a single line, using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-m`}</code>{` to provide the message in line works.  If not, you may want to use your preferred code editor for the task. In order to use your preferred edit, first ensure that you've configured Git with this information.  For instance, to use VS Code for all commits, you could run the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` config `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`--global`}</span>{` core.editor `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"code"`}</span></code></pre></div>
    <p>{`To use Vim, you would run the following version:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` config `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`--global`}</span>{` core.editor `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"vim"`}</span></code></pre></div>
    <p>{`If you want to use a particular editor only for the current repository, you would simply omit the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--global`}</code>{` flag in the previous command.`}</p>
    <p>{`Running `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`git commit`}</code>{` will open the editor with a template commit message for you to complete.`}</p>
    <h4 {...{
      "id": "commit-messages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#commit-messages",
        "aria-label": "commit messages permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Commit messages`}</h4>
    <p>{`Above all, commit messages should be informative and to the point.  Remember, these are for the benefit of future you and any collaborators.  Things may be perfectly clear when you're in the "zone" coding, but they might not be nearly so a day, month, or year later.  Ideally, you'd want to be able to understand the changes made to a repository by inspecting the commit messages.`}</p>
    <p>{`Here is an example of a bad commit message:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Added a file`}</code></pre></div>
    <p>{`What file?  Sure, one could inspect the details of the change compared to the previous and/or following commits (diff), but why make things difficult for yourself and others?`}</p>
    <p>{`Here is an example of a better commit message:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Added README.md`}</code></pre></div>
    <p>{`Much better.  We now at least know what file was changed, but `}<em parentName="p">{`why`}</em>{` did you add that file?  What purpose does it serve in the project?`}</p>
    <p>{`Here is an example of an even better commit message:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Added README.md

This file provides an introduction to the project (instructions for installation, running tests, and an overview of modules). `}</code></pre></div>
    <p>{`The first line in the commit message will be used for the summary.  Think of it as a (short!) title.  What follows on subsequent lines in the example above is a more detailed description.  While this extended description is not always necessary, it is often useful.`}</p>
    <h3 {...{
      "id": "takeaways",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#takeaways",
        "aria-label": "takeaways permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Takeaways`}</h3>
    <ul>
      <li parentName="ul">{`Write commit messages so that others can understand the motivation for the change.`}
        <ul parentName="li">
          <li parentName="ul">{`If something goes wrong in the future with the project, clear commit messages can help to quickly narrow down where the problem may have been introduced.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Keep the first line of each commit message short and to the point.`}</li>
      <li parentName="ul">{`Don't be afraid to write a longer description below the first line as needed.`}</li>
    </ul>
    <h1 {...{
      "id": "putting-it-all-together",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#putting-it-all-together",
        "aria-label": "putting it all together permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Putting it all together`}</h1>
    <h2 {...{
      "id": "creating-a-repository-locally-git-init",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-a-repository-locally-git-init",
        "aria-label": "creating a repository locally git init permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating a repository locally (`}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`git init`}</code>{`)`}</h2>
    <ol>
      <li parentName="ol">{`Using the command line, navigate to the location you want to store your repository:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`-p`}</span>{` ~/repos/git-basics
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` ~/repos/git-basics`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Initialize a repository:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` init`}</code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Create a file:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` README.md`}</code></pre></div>
    <p>{`Check the status of the repository:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` status

On branch master

No commits yet

Untracked files:
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`use `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"git add <file>..."`}</span>{` to include `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` what will be committed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        README.md

nothing added to commit but untracked files present `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`use `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"git add"`}</span>{` to track`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Track and stage the file:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` README.md`}</code></pre></div>
    <p>{`Let's see how the status has changed:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` status

On branch master

No commits yet

Changes to be committed:
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`use `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"git rm --cached <file>..."`}</span>{` to unstage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        new file:   README.md`}</code></pre></div>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Commit the change:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` commit `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`-m`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"My first commit"`}</span></code></pre></div>
    <p>{`Let's see how the status has changed after committing:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span"></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` status
On branch master
nothing to commit, working tree clean`}</code></pre></div>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Rinse and repeat.`}</li>
    </ol>
    <p>{`You can see a summary of all commits so far using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`git log`}</code>{`.`}</p>
    <p>{`Use your up and down arrow keys to navigate forward and backward through history.  Hit `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`q`}</code>{` to exit this view.`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "umbrella"
        }}>{`☂️`}</span>{` if you ever accidentally stage a file or a directory, you can unstage it using the `}</undefined><code parentName="p" {...{
        "className": "language-text"
      }}>{`git reset`}</code>{` command.  This is especially useful if you decide you want to split a bunch of changes into a few smaller commits to explain everything clearly.`}</p>
    <h2 {...{
      "id": "cloning-a-remote-repository",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cloning-a-remote-repository",
        "aria-label": "cloning a remote repository permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Cloning a remote repository`}</h2>
    <p>{`After completing this tutorial, `}<a parentName="p" {...{
        "href": "/tutorials/github-intro"
      }}>{`see the GitHub tutorial for an example involving remotes`}</a></p>
    <h1 {...{
      "id": "workflows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#workflows",
        "aria-label": "workflows permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Workflows`}</h1>
    <h2 {...{
      "id": "feature-branch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#feature-branch",
        "aria-label": "feature branch permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Feature-branch`}</h2>
    <p>{`The `}<strong parentName="p">{`feature-branch`}</strong>{` workflow is commonly used to address a specific task, such as developing a new feature (ex. extending a tokenizer to cover a specialized domain) or fixing a particular bug.`}</p>
    <p>{`First, you `}<em parentName="p">{`branch`}</em>{` off of a working version of your code in order to address your specific task by making and `}<em parentName="p">{`committing`}</em>{` changes.  Working on a branch allows you to make isolated changes without risk of breaking things on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`master`}</code>{` branch (which is expected to be stable and functioning).`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/790e913644f216843e3371ceb3934b5c/573d3/git-feature-branch-workflow-1.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "77.02702702702703%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABcSAAAXEgFnn9JSAAABYklEQVQ4y61TO0vDUBjN6u7q5Orq6uDgj5AuglsHcRMRnDpJcRVUdBEcnToLgihFMRVaTK2lmvSRtklKmyYmuTlimsfNExW/5bv3fvee8z3OZfDPxvz2gW3bvk9aM38FIw4IHE/HGPpyGnuSTyKKAXreIjYISQY1yWxdKL1j7ZBFsz919sRNgkljSiKibfPsGQs7ZVQE1dlbJFKy1wte0rB+XMXRteCfezFFNbBx/oJiicOg3wPb6GI8UqBpmk/qA3oMdw0Fc/kb5E5roRZ822tPxfz2LVaLLD51HdpkBEmWoet6HJAexEO9C67VDp17sUpTRFuaprYm1EPiZtIRh+B5nroYlC0OZRiG4d+PKiJWMvsxxuLuPbYu64Hm3FhroGFpv4zcSTV1kEw0u1pnguXCI/au3mJDEWQdKwdPyF9wM7AsQJrNMEmqbEyLZMosJuzgK8XBCAWQps8ffb3sWDjDL+FYiwBphSZeAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Create a feature branch",
              "title": "Create a feature branch",
              "src": "/static/790e913644f216843e3371ceb3934b5c/fcda8/git-feature-branch-workflow-1.png",
              "srcSet": ["/static/790e913644f216843e3371ceb3934b5c/12f09/git-feature-branch-workflow-1.png 148w", "/static/790e913644f216843e3371ceb3934b5c/e4a3f/git-feature-branch-workflow-1.png 295w", "/static/790e913644f216843e3371ceb3934b5c/fcda8/git-feature-branch-workflow-1.png 590w", "/static/790e913644f216843e3371ceb3934b5c/efc66/git-feature-branch-workflow-1.png 885w", "/static/790e913644f216843e3371ceb3934b5c/c83ae/git-feature-branch-workflow-1.png 1180w", "/static/790e913644f216843e3371ceb3934b5c/573d3/git-feature-branch-workflow-1.png 1650w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Create a feature branch`}</figcaption>{`
  `}</figure></p>
    <p>{`After completing development of your feature, `}<em parentName="p">{`merge`}</em>{` your changes back into the code from which you branched (ex. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`master`}</code>{`).`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/502cfdebfb8c7a451bc2da78ca939885/573d3/git-feature-branch-workflow-2.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "77.02702702702703%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABcSAAAXEgFnn9JSAAABu0lEQVQ4y4VTSywDURTtlp2lvZ29BCsbsbC1sBZEfCIRG2xsfJIuLP0SiRAWTQjSlW6kRIt+aWmaqk992hGlVdOZ994R83md6kxNMpN33733zLnn3msDAMaY8upnQgkoozDzWd3pj81o/HWaJVJmHc8BjYl5MY+d6CZCrxcaAAWlKtu3goCJw34sndt1RHOGv5d6iZ47Nzo2GjHt6gNAdC7K9zmXwrCzE/aTcTALlrYybSjD51cOexEHYpkg7M4E2hdCSGYKPOEgcI3Ik6CxZwCDNaBMCEAJJ9azFkb9+Cl8yQ/Fvnz8RN2oB232EJihaRYaqqXFn7PoWgxj2XUDScwjKYg8uFCkGNuOYvXosaJpJhpC0ewomkbt0DG6VyI8iGqtFWWKmf04HGcv/wMqcIRAlorwJzJIZ1XdCGUc8CqVQ82gGy1z/upzaNSQERnptywkSSpjQbUYh+cBwfsPA3PLklWHNy6gYdKLka0YB9QZ3gnfaJ69QO/6teVwVwAGbgU0zfgwtZvgo6H7Uu8iWuf9GNiI8emsWrKayFCUSQWDkiz0/9UrT6y2x8zyR+abooH8BTOzy+e3BPgDX6N510jN9swAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Commit changes, merge, and delete feature branch",
              "title": "Commit changes, merge, and delete feature branch",
              "src": "/static/502cfdebfb8c7a451bc2da78ca939885/fcda8/git-feature-branch-workflow-2.png",
              "srcSet": ["/static/502cfdebfb8c7a451bc2da78ca939885/12f09/git-feature-branch-workflow-2.png 148w", "/static/502cfdebfb8c7a451bc2da78ca939885/e4a3f/git-feature-branch-workflow-2.png 295w", "/static/502cfdebfb8c7a451bc2da78ca939885/fcda8/git-feature-branch-workflow-2.png 590w", "/static/502cfdebfb8c7a451bc2da78ca939885/efc66/git-feature-branch-workflow-2.png 885w", "/static/502cfdebfb8c7a451bc2da78ca939885/c83ae/git-feature-branch-workflow-2.png 1180w", "/static/502cfdebfb8c7a451bc2da78ca939885/573d3/git-feature-branch-workflow-2.png 1650w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Commit changes, merge, and delete feature branch`}</figcaption>{`
  `}</figure></p>
    <p>{`Once you've successfully merged your changes, you can safely delete the feature branch.  Those changes have become part of the commit history of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`master`}</code>{` branch.`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "eyes"
        }}>{`👀`}</span>{` `}</undefined><em parentName="p"><strong parentName="em">{`TIP`}</strong>{`: In team settings, avoid long-lived branches as much as possible.  This minimizes your chances of encountering a merge conflict `}<sup parentName="em" {...{
          "id": "fnref-1"
        }}><a parentName="sup" {...{
            "href": "#fn-1",
            "className": "footnote-ref"
          }}>{`1`}</a></sup>{`.`}</em></p>
    <h3 {...{
      "id": "step-1-create-and-checkout-a-new-branch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1-create-and-checkout-a-new-branch",
        "aria-label": "step 1 create and checkout a new branch permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1: Create and checkout a new branch`}</h3>
    <p>{`We can create a branch and switch to it in a single step:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` checkout `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`-b`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"new-feature"`}</span></code></pre></div>
    <h3 {...{
      "id": "step-2-make-and-commit-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2-make-and-commit-changes",
        "aria-label": "step 2 make and commit changes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2: Make and commit changes`}</h3>
    <p>{`Make and commit changes to your local branch, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`new-feature`}</code>{`, (ex. improving your tokenizer, fixing a particular bug, etc.).  `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# prepare to commit all changes to the current directory`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` commit`}</code></pre></div>
    <h3 {...{
      "id": "step-3-merge-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3-merge-changes",
        "aria-label": "step 3 merge changes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3: Merge changes`}</h3>
    <p>{`Once you're satisfied and want to bring those changes into the "mainline" of your code, you would `}<em parentName="p">{`merge`}</em>{` your changes:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# assuming master is the name of your "core" branch`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` checkout master
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` merge `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"new-feature"`}</span></code></pre></div>
    <h3 {...{
      "id": "step-4-delete-the-merged-feature-branch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-4-delete-the-merged-feature-branch",
        "aria-label": "step 4 delete the merged feature branch permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4: Delete the merged feature branch`}</h3>
    <p>{`Once your changes have been successfully merged, you can safely delete or "prune" the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`new-feature`}</code>{` branch:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` branch `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`-d`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"new-feature"`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-d`}</code>{` corresponds to `}<em parentName="p">{`delete`}</em>{`.`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "umbrella"
        }}>{`☂️`}</span>{` Sometimes a branch may just be used to experiment and may never be merged back into the main line of the repository.  In such cases, you would simply discard it.`}</undefined></p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p><img parentName="p" {...{
        "src": "https://imgs.xkcd.com/comics/git.png",
        "alt": "Git according to XKCD"
      }}></img><sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <p>{`At first glance, Git may seem quite complicated.  Stick with it, though.  The payoff is worth the effort.`}</p>
    <h2 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h2>
    <ul>
      <li parentName="ul">{`To practice using remotes, see `}<a parentName="li" {...{
          "href": "/tutorials/github-intro"
        }}>{`the GitHub tutorial`}</a>{`.`}</li>
    </ul>
    <h1 {...{
      "id": "additional-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#additional-resources",
        "aria-label": "additional resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Additional resources`}</h1>
    <ul>
      <li parentName="ul">{`Download a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git`}</code>{` cheatsheet (quick reference) in your preferred language: `}<a parentName="li" {...{
          "href": "https://github.github.com/training-kit/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://github.github.com/training-kit/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://guides.github.com/introduction/git-handbook/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://guides.github.com/introduction/git-handbook/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://guides.github.com/activities/hello-world/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://guides.github.com/activities/hello-world/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://git-scm.com/book/en/v2",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://git-scm.com/book/en/v2`}</a></li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`See `}<a parentName="li" {...{
            "href": "https://docs.github.com/en/github/collaborating-with-issues-and-pull-requests/resolving-a-merge-conflict-using-the-command-line",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://docs.github.com/en/github/collaborating-with-issues-and-pull-requests/resolving-a-merge-conflict-using-the-command-line`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}><a parentName="li" {...{
            "href": "https://xkcd.com/1597/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://xkcd.com/1597/`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      